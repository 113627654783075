<template>
  <div class="fields">
    <form class="contact_form">
      <ul>
        <li>
          <input type="hidden"
            name="target-email-id"
            v-model="emailId"
            v-on:keyup="updateOutputUrl"
          />
        </li>
        <li>
          <input
            name="subject"
            placeholder="Subject"
            type="text"
            v-model="email.subject"
            v-on:keyup="updateOutputUrl"
          />
        </li>
        <li>
          <textarea
            name="body"
            placeholder="Message"
            type="text"
            v-model="email.body"
            v-on:keyup="updateOutputUrl"
          ></textarea>
          <input type="hidden" id="final-link-to-copy" :value="outputUrl" />
        </li>
      </ul>
    </form>

    <div class="tokyo_tm_button">
      <a class="ib-button" v-bind:href="outputUrl">
        Send Message
      </a>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.updateOutputUrl();
  },
  data() {
    return {
      outputUrl: "Type something",
      emailId: "toasterartist@gmail.com",
      email: {
        subject: "",
        body: ""
      }
    };
  },
  methods: {
    updateOutputUrl() {
      this.outputUrl = "mailto:" + this.emailId;
      const emailKeys = Object.keys(this.email);
      const remaining = emailKeys.filter(
        (key) => this.email[key].trim().length > 0
      );
      if (remaining.length > 0) {
        this.outputUrl += "?";
      }
      this.outputUrl += remaining
        .map((key) => `${key}=${encodeURI(this.email[key].trim())}`)
        .join("&");
    },
  }
};
</script>
