<template>
  <div class="tokyo_tm_contact">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <h3>Get in Touch</h3>
        </div>
      </div>
    </div>
    <EmailForm />
  </div>
</template>

<script>
  import EmailForm from "../components/EmailForm"

  export default {
    components: {
      EmailForm,
    },
  };
</script>
